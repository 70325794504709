/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import AppTextField from "../../../../components/atoms/TextField/AppTextField";
import { AppleLogo, MicrosoftLogo } from "../../../../assets/svg/index";
import GoogleLogo from "../../../../assets/svg/GoogleLogo";

export interface SocialFormProps {
  loading?: boolean;
  setLoading: (loading: any) => void;
  values?: any;
  errors?: any;
  profile?: any;
  setProfile: (profile: any) => void;
  setName: (name: any) => void;
  setEmail: (email: any) => void;
  handleSubmitRequestSocial: (access_token: string) => void;
}

export const SocialForm: React.FC<SocialFormProps> = ({
  loading,
  setLoading,
  values,
  errors,
  profile,
  setName,
  setEmail,
  setProfile,
  handleSubmitRequestSocial,
}) => {
  const [user, setUser] = useState<any>([]);

  const openLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
      setLoading(false);
    },
    onNonOAuthError: (error) => {
      setLoading(false);
      console.log("Login Failed:", error);
    },
    onError: (error) => {
      setLoading(false);
      console.log("Login Failed:", error);
    },
  });

  const login = () => {
    setLoading(true);
    openLogin();
  };

  useEffect(() => {
    if (user) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
        })
        .then((res) => {
          setName(res.data.name);
          setEmail(res.data.email);
          setProfile(res.data);
          handleSubmitRequestSocial(user.access_token);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  return (
    <div>
      {profile ? (
        <>
          <AppTextField
            label="Usuário"
            title="Usuário"
            value={values.userName}
            setValue={setName}
            obrigatorio={true}
            error={errors.userName}
            disabled={loading || true}
          />
          <AppTextField
            label="E-mail"
            title="E-mail"
            type="email"
            value={values.email}
            setValue={setEmail}
            obrigatorio={true}
            error={errors.email}
            disabled={loading || true}
          />
        </>
      ) : (
        <div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "15px" }}>
          <GoogleLogo onClick={() => login()} />
          <AppleLogo />
          <MicrosoftLogo />
        </div>
      )}
    </div>
  );
};
