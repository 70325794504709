import { useTheme } from "@mui/material";
import AppButtonTheme from "../../../components/molecules/ButtonTheme/AppButtonTheme";
import Menu from "../../../components/molecules/Menu/Menu";

export const Layout = ({ props }: any) => {
  const theme = useTheme();

  return (
    <>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          background: theme.palette.background.default,
        }}
      >
        <AppButtonTheme />
        {props}
        <Menu />
      </div>
    </>
  );
};
