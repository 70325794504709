import "./SignUpBusinessScreen.css";
import { Checkbox, Typography } from "@mui/material";
import AppTextField from "../../../components/atoms/TextField/AppTextField";
import { useCallback, useContext, useEffect, useState } from "react";
import MainLoginRegister from "../../../components/organisms/MainLoginRegister/MainLoginRegister";
import { useFormik } from "formik";
import * as Yup from "yup";
import { cnpj, cpf } from "cpf-cnpj-validator";
import { showSnackbar } from "../../../utils/snackbar";
import { useNavigate } from "react-router-dom";
import {
  hasLowerCase,
  hasNumber,
  hasSpecialChar,
  hasUpperCase,
  isLongEnough,
} from "../../../utils/password";
import { AuthContext } from "../../../context";
import { LoadingButton } from "@mui/lab";
import AppModal from "../../../components/organisms/Modal/app-Modal";

interface ISignUpBussines {
  nameEstablishment: string;
  phone?: string;
  cpfCnpj: string;
  userName: string;
  email: string;
  password: string;
  repeatPassword: string;
}

export const SignUpBusinessScreen = () => {
  const { handleCreateEstablishment } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleOpenTerms = () => setTermsOpen(true);
  const handleCloseTerms = () => setTermsOpen(false);
  const [termsOpen, setTermsOpen] = useState(false);
  const [showUserDiv, setShowUserDiv] = useState(true);
  // const [birthDate, setBirthDate] = useState();
  const [checkedTerms, setCheckedTerms] = useState(false);

  //#region handleSubmit/Validations
  const { values, setFieldValue, setFieldError, handleSubmit, errors } =
    useFormik({
      initialValues: {
        nameEstablishment: "",
        phone: "",
        cpfCnpj: "",
        userName: "",
        email: "",
        password: "",
        repeatPassword: "",
      },
      validationSchema: Yup.object().shape({
        nameEstablishment: Yup.string().required(
          "Nome do estabelecimento é um campo obrigatório"
        ),
        cpfCnpj: Yup.string()
          .required("CPF ou CNPJ é um campo obrigatório")
          .test(
            "is-cpf-cnpj",
            'O campo "CPF/CNPJ" não é um CPF ou CNPJ válido.',
            (value: any) => {
              return value && (cpf.isValid(value) || cnpj.isValid(value));
            }
          ),
        userName: Yup.string().required(
          "Nome do usuário é um campo obrigatório"
        ),
        email: Yup.string()
          .required("E-mail é um campo obrigatório")
          .email("E-mail precisa de um valor valido"),
        password: Yup.string()
          .required("Senha é um campo obrigatório")
          .min(8, ""),
        repeatPassword: Yup.string()
          .required("Repetir senha é um campo obrigatório")
          .oneOf(
            [Yup.ref("password")],
            "A senha informada não é igual a de cima"
          ),
      }),
      validateOnChange: false,
      validateOnBlur: false,
      onSubmit: ({
        nameEstablishment,
        phone,
        cpfCnpj,
        userName,
        email,
        password,
        repeatPassword,
      }) => {
        if (onValidate()) {
        } else {
          handleSubmitRequest({
            nameEstablishment,
            phone,
            cpfCnpj,
            userName,
            email,
            password,
            repeatPassword,
          });
        }
      },
    });

  const onValidate = useCallback(() => {
    if (
      !values.nameEstablishment ||
      !values.cpfCnpj ||
      !values.userName ||
      !values.email ||
      !values.password ||
      !values.repeatPassword
    ) {
      return true;
    }
    return false;
  }, [values]);

  const handleSubmitRequest = async ({
    nameEstablishment,
    phone,
    cpfCnpj,
    userName,
    email,
    password,
    repeatPassword,
  }: ISignUpBussines) => {
    if (!checkedTerms) {
      showSnackbar("É preciso aceitar os termos e condições", "error");
      return;
    }
    if (!loading) {
      setLoading(true);
      const create: any = await handleCreateEstablishment(
        nameEstablishment,
        phone?.replace(/\D/g, ""),
        cpfCnpj,
        userName,
        email,
        password,
        repeatPassword
      );
      if (create) navigate("/schedule");
      setLoading(false);
    }
  };

  const isPasswordValid = () => {
    return (
      hasUpperCase(values.password) &&
      hasLowerCase(values.password) &&
      hasSpecialChar(values.password) &&
      isLongEnough(values.password) &&
      hasNumber(values.password)
    );
  };

  //#endregion

  //#region Events handleChanges

  const handleChangeTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedTerms(event.target.checked);
  };

  const handleTerms = (value: boolean) => {
    setCheckedTerms(value);
    handleCloseTerms();
  };

  //#endregion

  useEffect(() => {
    if (!values.nameEstablishment || !values.cpfCnpj) {
      setShowUserDiv(false);
    } else {
      setShowUserDiv(true);
    }
  }, [values.nameEstablishment, values.cpfCnpj]);

  //#region SetFieldValues

  const setNameEstablishment = useCallback(
    (value: any) => {
      setFieldValue("nameEstablishment", value);
      setFieldError("nameEstablishment", "");
    },
    [setFieldValue, setFieldError]
  );
  const setPhone = useCallback(
    (value: any) => {
      setFieldValue("phone", value);
      setFieldError("phone", "");
    },
    [setFieldValue, setFieldError]
  );
  const setCPFCNPJ = useCallback(
    (value: any) => {
      setFieldValue("cpfCnpj", value);
      setFieldError("cpfCnpj", "");
    },
    [setFieldValue, setFieldError]
  );
  const setName = useCallback(
    (value: any) => {
      setFieldValue("userName", value);
      setFieldError("userName", "");
    },
    [setFieldValue, setFieldError]
  );
  const setEmail = useCallback(
    (value: any) => {
      setFieldValue("email", value);
      setFieldError("email", "");
    },
    [setFieldValue, setFieldError]
  );
  const setSenha = useCallback(
    (value: any) => {
      setFieldValue("password", value);
      setFieldError("password", "");
    },
    [setFieldValue, setFieldError]
  );
  const setRepetirSenha = useCallback(
    (value: any) => {
      setFieldValue("repeatPassword", value);
      setFieldError("repeatPassword", "");
    },
    [setFieldValue, setFieldError]
  );
  //#endregion

  const bodyRegister = () => {
    return (
      <>
        <form onSubmit={handleSubmit} noValidate className="form">
          <Typography variant="h4" sx={{ color: "text.secondary" }}>
            Cadastrar Estabelecimento
          </Typography>
          <div>
            <div>
              <p style={{ textAlign: "start" }}>Estabelecimento</p>
              <AppTextField
                label="Nome"
                title="Nome"
                value={values.nameEstablishment}
                setValue={setNameEstablishment}
                maxLength={80}
                minLenght={3}
                obrigatorio={true}
                error={errors.nameEstablishment}
              ></AppTextField>
              <AppTextField
                className="form-input"
                label="Telefone"
                title="Telefone"
                type="email"
                value={values.phone}
                setValue={setPhone}
                maxLength={15}
                error={errors.phone}
              ></AppTextField>
              <AppTextField
                label="CPF/CNPJ"
                title="CPF/CNPJ"
                obrigatorio={true}
                value={values.cpfCnpj}
                setValue={setCPFCNPJ}
                maxLength={17}
                minLenght={12}
                error={errors.cpfCnpj}
              ></AppTextField>
            </div>
            {showUserDiv && (
              <div className="div-user">
                <p style={{ textAlign: "start" }}>Usuário</p>
                <AppTextField
                  label="Nome"
                  title="Nome"
                  obrigatorio={true}
                  value={values.userName}
                  setValue={setName}
                  maxLength={80}
                  minLenght={2}
                  error={errors.userName}
                ></AppTextField>
                <AppTextField
                  className="form-input"
                  label="E-mail"
                  title="E-mail"
                  type="email"
                  obrigatorio={true}
                  value={values.email}
                  setValue={setEmail}
                  minLenght={5}
                  error={errors.email}
                ></AppTextField>
                <AppTextField
                  className="form-input"
                  label="Senha"
                  title="Senha"
                  value={values.password}
                  setValue={setSenha}
                  maxLength={80}
                  minLenght={8}
                  obrigatorio={true}
                  isPassword={true}
                  type={"password"}
                  error={errors.password}
                ></AppTextField>
                {isPasswordValid() && (
                  <AppTextField
                    className="form-input"
                    label="Repetir Senha"
                    title="Repetir Senha"
                    type={"password"}
                    value={values.repeatPassword}
                    setValue={setRepetirSenha}
                    maxLength={80}
                    minLenght={8}
                    obrigatorio={true}
                    isPassword={true}
                    error={errors.repeatPassword}
                  ></AppTextField>
                )}

                {/* <AppDatePicker label={"Data de Nascimento"} value={birthDate} onChange={setBirthDate} /> */}
                {/* <AppTextField label="Foto de Perfil" title="Foto de Perfil" type="file"></AppTextField> */}
              </div>
            )}
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={checkedTerms}
                disabled={true}
                onChange={handleChangeTerms}
                inputProps={{ "aria-label": "controlled" }}
              />
              <p style={{ margin: 0 }}>
                Aceito os{" "}
                <a href="#" onClick={handleOpenTerms}>
                  termos de uso
                </a>
              </p>

              <AppModal
                open={termsOpen}
                handleClose={handleCloseTerms}
                handleTerms={handleTerms}
              />
            </div>
            <div className="Button Button-Establishment">
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                loading={loading}
              >
                Cadastrar
              </LoadingButton>
            </div>
            <div className="Button Button-Establishment">
              <LoadingButton
                variant="outlined"
                color="warning"
                onClick={() => navigate("/login")}
                loading={loading}
              >
                Entrar
              </LoadingButton>
            </div>
          </div>
        </form>
      </>
    );
  };

  return <MainLoginRegister body={bodyRegister} />;
};
