import { CircularProgress } from "@mui/material";
import { ContainerLoading } from "./styles";

type PropsLoading = {
  height?: string;
  width?: string;
  size?: number;
  color?: string;
};

const Loading = ({ ...props }: PropsLoading) => {
  const height = props.height || "100vh";
  const width = props.width || "auto";
  const size = props.size || 80;
  const color = props.color || "white";
  return (
    <ContainerLoading
      style={{
        height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width,
      }}
    >
      <CircularProgress
        size={size}
        style={{
          color,
        }}
      />
    </ContainerLoading>
  );
};

export default Loading;
