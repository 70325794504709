import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FormControl, InputLabel } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";

export interface option {
  value: any;
  label: string;
}

interface SelectFieldProps {
  options: option[];
  multiple?: boolean;
  displayEmpty?: boolean;
  label?: string;
  title?: string;
  className?: string;
  isSubmitted?: boolean;
  type?: string;
  obrigatorio?: boolean;
  value?: string[];
  color?: "primary" | "secondary" | "error" | "info" | "success" | "warning";
  error?: string | undefined;
  onChange?: (event: SelectChangeEvent<any>) => void;
}

const AppSelectField: React.FC<SelectFieldProps> = ({
  options,
  multiple = false,
  displayEmpty = false,
  label,
  className,
  value = [],
  obrigatorio = false,
  onChange,
  color = "primary",
  error,
}) => {
  const handleChange = (event: SelectChangeEvent<any>) => {
    if (onChange) {
      onChange(event);
    }
  };

  const renderValue = (selected: any) => {
    if (multiple && Array.isArray(selected)) {
      return selected.map((value) => options.find((option) => option.value === value)?.label).join(", ");
    }
    return options.find((option) => option.value === selected)?.label;
  };

  return (
    <FormControl sx={{ width: "100%" }} error={error ? true : false}>
      <InputLabel id="demo-simple-select-required-label">{label}</InputLabel>
      <Select
        multiple={multiple}
        displayEmpty={displayEmpty}
        value={value}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={renderValue}
        sx={{ width: "100%" }}
        className={className ?? ""}
        required={obrigatorio}
        color={color}
        error={error ? true : false}
      >
        <MenuItem value="">
          <em>{displayEmpty ? label : "None"}</em>
        </MenuItem>
        {options.map((option: option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error ? error : ""}</FormHelperText>}
    </FormControl>
  );
};

export default AppSelectField;
