import { Typography, Button, Box, Paper, Grid, useTheme, styled } from "@mui/material";
import { Link } from "react-router-dom";
import AppButtonTheme from "../../../components/molecules/ButtonTheme/AppButtonTheme";

export const HomePageScreen = () => {
  const theme = useTheme();

  const FeaturePaper: any = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <div style={{ background: theme.palette.background.default, width: "100vw", height: "100vh" }}>
      <AppButtonTheme />
      <Box sx={{ paddingY: 4 }}>
        <Typography variant="h2" gutterBottom align="center" sx={{ color: "text.secondary" }}>
          Bem-vindo ao Sistema de Agendamento
        </Typography>
        <Typography variant="h5" gutterBottom align="center" sx={{ color: "text.secondary" }}>
          A solução completa para barbeiros, cabeleireiros e prestadores de serviços.
        </Typography>
        <Box display="flex" justifyContent="center" my={4}>
          <Button variant="contained" color="primary" component={Link} to="/login">
            Login / Cadastro
          </Button>
          <Button variant="outlined" color="primary" sx={{ ml: 2 }} component={Link} to="/signup">
            Cadastro de Estabelecimento
          </Button>
        </Box>
      </Box>

      <Box my={4} sx={{ paddingX: 4 }}>
        <Typography variant="h4" gutterBottom align="center" sx={{ color: "text.secondary" }}>
          Recursos
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <FeaturePaper elevation={3}>
              <Typography variant="h6">Agendamento Fácil</Typography>
              <Typography>Agendamentos com apenas alguns cliques.</Typography>
            </FeaturePaper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FeaturePaper elevation={3}>
              <Typography variant="h6">Notificações Automáticas</Typography>
              <Typography>Reduza faltas com lembretes.</Typography>
            </FeaturePaper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FeaturePaper elevation={3}>
              <Typography variant="h6">Relatórios Detalhados</Typography>
              <Typography>Acompanhe o crescimento do seu negócio.</Typography>
            </FeaturePaper>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
