import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Box, Button } from "@mui/material";
import { AuthContext } from "../../../context";
import { Layout } from "../../../components/organisms/Layout/Layout";

export const DashboardScreen: React.FC = () => {
  const { handleLogout, validateSession } = useContext(AuthContext);

  useEffect(() => {
    validateSession();
  }, [validateSession]);

  const navigate = useNavigate();

  const handleLogoutClick = () => {
    handleLogout();
    navigate("/");
  };

  const body = () => {
    return (
      <Container maxWidth="sm">
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Painel de Controle
          </Typography>
          <Typography paragraph>Bem-vindo ao seu painel. Aqui você pode gerenciar suas configurações.</Typography>
          <Button variant="contained" color="secondary" onClick={handleLogoutClick}>
            Sair
          </Button>
          <Button variant="contained" color="primary" onClick={() => navigate("/list")}>
            List
          </Button>
        </Box>
      </Container>
    );
  };

  return (
    <>
      <Layout props={body()} />
    </>
  );
};
