import React from "react";
import Button from "@mui/material/Button";
import { useMediaQuery, useTheme } from "@mui/material";

interface ButtonProps {
  label: string;
  className?: string;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | "appcolor";
  buttonColor?: string; // Adicione esta linha
  onClick?: () => void;
}

const AppButton: React.FC<ButtonProps> = ({
  label,
  className,
  color = "primary",
  buttonColor,
  onClick,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Button
      sx={{ mr: isMobile ? 1 : 2, mt: 3 }}
      color={color === "appcolor" ? undefined : color}
      style={{ backgroundColor: buttonColor }}
      variant="contained"
      className={className}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default AppButton;
