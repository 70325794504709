import { createTheme } from "@mui/material";
import { blue, lightBlue } from "@mui/material/colors";
import colorPalette from "../../styles/colorPalette";

export const LightTheme = createTheme({
  palette: {
    primary: {
      main: blue[500], // Uma cor vibrante para o tema claro
      dark: blue[800],
      light: blue[300],
      contrastText: "#fff",
    },
    secondary: {
      main: lightBlue[500], // Uma cor complementar para a cor primária
      dark: lightBlue[400],
      light: lightBlue[300],
      contrastText: "#fff",
    },
    background: {
      paper: "#ffffff",
      default: blue[100], // Um fundo mais claro para o tema light
    },
    text: {
      primary: "#000000",
      secondary: "#000000",
    },
  },
  components: {
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: colorPalette.light,
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#000",
          },
        },
      },
    },
  },
});
