import React, { createContext, useContext, useEffect, useState } from "react";

// Crie uma constante para a largura máxima de um dispositivo móvel.
const mobileWidthThreshold = 768;

// Crie o contexto com um valor padrão falso.
const MobileContext = createContext(false);

// Exporte o hook para facilitar o acesso ao contexto.
export const useMobileContext = () => useContext(MobileContext);

// Crie um provider que atualize o valor baseado no tamanho da janela.
export const MobileProvider = ({ children }: any) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidthThreshold);

  useEffect(() => {
    // Handler para atualizar o estado baseado no tamanho da janela.
    const handleResize = () => {
      setIsMobile(window.innerWidth < mobileWidthThreshold);
    };

    // Adicione o listener para o evento 'resize'.
    window.addEventListener("resize", handleResize);

    // Remova o listener quando o componente for desmontado.
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return <MobileContext.Provider value={isMobile}>{children}</MobileContext.Provider>;
};
