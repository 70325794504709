import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { EmailValidScreen, EmailVerifiedScreen } from "../screens/unAuthenticated";

const EmailVerifiedRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/email-verified" element={<EmailVerifiedScreen />} />
      <Route path="/email" element={<EmailValidScreen />} />
      <Route path="*" element={<Navigate to="/email-verified" replace />} />
    </Routes>
  );
};

export default EmailVerifiedRoutes;
