import React, { createContext, useContext, useState, ReactNode } from "react";

type SignUpBusinessContextType = {
  // Defina os tipos dos dados que deseja armazenar no contexto
  // Por exemplo:
  nome: string;
  telefone: string;
  setNome: (nome: string) => void;
  setTelefone: (telefone: string) => void;
};

const SignUpBusinessContext = createContext<
  SignUpBusinessContextType | undefined
>(undefined);

export const useSignUpBusinessContext = () => {
  const context = useContext(SignUpBusinessContext);
  if (!context) {
    throw new Error(
      "useSignUpBusinessContext must be used within a SignUpBusinessProvider"
    );
  }
  return context;
};

type SignUpBusinessProviderProps = {
  children: ReactNode;
};

export const SignUpBusinessProvider = ({
  children,
}: SignUpBusinessProviderProps) => {
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");

  return (
    <SignUpBusinessContext.Provider
      value={{ nome, telefone, setNome, setTelefone }}
    >
      {children}
    </SignUpBusinessContext.Provider>
  );
};
