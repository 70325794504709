import React, { useContext, useEffect, useState } from "react";
import { UseSimpleFetch } from "../../../hooks/useSimpleFetch";
import { mountHeaderToken } from "../../../services/api";
import { getStoreData } from "../../../utils/storage";
import { AuthContext } from "../../../context";

export const ListScreen: React.FC = () => {
  const [data, setData] = useState(null);
  const { setToken } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      mountHeaderToken(await getStoreData("token"));
      const response = await UseSimpleFetch("/schedulings");
      setToken(response);
      setData(response.data);
    };

    fetchData();
  }, []);

  return (
    <div>
      <p>Dados da API:</p>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
};
