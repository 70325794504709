import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { DashboardScreen, ListScreen, ScheduleScreen, ValidScreen } from "../screens/authenticated";
import { AuthContext } from "../context";

const PrivateRoutes: React.FC = () => {
  const { isAuth } = useContext(AuthContext);
  return (
    <Routes>
      {isAuth ? (
        <>
          <Route path="/dashboard" element={<DashboardScreen />} />
          <Route path="/list" element={<ListScreen />} />
          <Route path="/schedule" element={<ScheduleScreen />} />
          <Route path="/valid" element={<ValidScreen />} />
          <Route path="*" element={<Navigate to="/schedule" replace />} />

          {/* Adicione outras rotas privadas aqui */}
        </>
      ) : (
        <Route path="*" element={<Navigate to="/login" />} />
      )}
    </Routes>
  );
};

export default PrivateRoutes;
