import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { ThemeProvider } from "@mui/material";

import { LightTheme, DarkTheme } from "../screens/themes";

interface IThemeContextData {
  theme: boolean;
  toggleTheme: () => void;
}

interface IAppThemeProviderProps {
  children: React.ReactNode;
}

const ThemeContext = createContext({} as IThemeContextData);

export const useAppThemeContext = () => {
  return useContext(ThemeContext);
};

export const AppThemeProvider: React.FC<IAppThemeProviderProps> = ({
  children,
}) => {
  const [theme, setTheme] = useState<boolean>(
    () => sessionStorage.getItem("theme") === "dark"
  );
  const toggleTheme = useCallback(() => {
    setTheme((prevTheme) => {
      const newTheme = !prevTheme;
      sessionStorage.setItem("theme", newTheme ? "dark" : "light");
      return newTheme;
    });
  }, []);

  const themeValue = useMemo(() => {
    return theme ? DarkTheme : LightTheme;
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <ThemeProvider theme={themeValue}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
