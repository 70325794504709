import { Layout } from "../../../components/organisms/Layout/Layout";

export const ScheduleScreen = () => {
  const body = () => {
    return <div>Estou na tela de schedule!</div>;
  };

  return (
    <>
      <Layout props={body()} />
    </>
  );
};
