import React from "react";
import AppBox from "../../atoms/Box/app-Box";
import AppText from "../../atoms/Text/app-Text";

interface ModalContentProps {
  title: string;
  description: string;
}

const AppModalContent: React.FC<ModalContentProps> = ({
  title,
  description,
}) => {
  return (
    <AppBox>
      <AppText variant="h6" component="h2" fontWeight="bold">
        {title}
      </AppText>
      <AppText sx={{ mt: 2 }}>{description}</AppText>
    </AppBox>
  );
};
export default AppModalContent;
