import React, { useState } from "react";
import { BottomNavigation, BottomNavigationAction, useMediaQuery, useTheme } from "@mui/material";
import { Explore, Favorite, AccountCircle, Schedule, InsertChart } from "@mui/icons-material";
import { makeStyles } from "@material-ui/styles";
import { useLocation, useNavigate } from "react-router-dom";

const menuStyles = makeStyles({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
  rootWeb: {
    position: "fixed",
    bottom: "100px", // Ajuste para a distância do fundo no modo web
    borderRadius: "10px",
    width: "60%",
    marginLeft: "20%",
  },
});

const Menu: React.FC = () => {
  const theme = useTheme();
  const bottomColor = "#ffff";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = menuStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(location.pathname.replace("/", ""));

  return (
    <BottomNavigation
      showLabels
      className={isMobile ? classes.root : classes.rootWeb}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        if (location.pathname !== `/${newValue}`) {
          navigate(`/${newValue}`);
        }
      }}
    >
      <BottomNavigationAction
        sx={{
          color: bottomColor,
        }}
        label="Explorar"
        icon={<Explore />}
      />
      <BottomNavigationAction
        sx={{
          color: bottomColor,
        }}
        label="Relatório"
        value="dashboard"
        icon={<InsertChart />}
      />
      <BottomNavigationAction
        sx={{
          color: bottomColor,
        }}
        label="Agenda"
        value="schedule"
        icon={<Schedule />}
      />
      <BottomNavigationAction
        sx={{
          color: bottomColor,
        }}
        label="Lembretes"
        icon={<Favorite />}
      />
      <BottomNavigationAction
        sx={{
          color: bottomColor,
        }}
        label="Perfil"
        icon={<AccountCircle />}
      />
    </BottomNavigation>
  );
};

export default Menu;
