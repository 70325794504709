import NightlightIcon from "@mui/icons-material/Nightlight";
import LightModeIcon from "@mui/icons-material/LightMode";
import { useAppThemeContext } from "../../../context";

const AppButtonTheme: React.FC<any> = () => {
  const { theme, toggleTheme } = useAppThemeContext();

  return (
    <div style={{ position: "absolute", right: 14, top: 14, color: theme ? "#dbdbdb" : "black" }} onClick={toggleTheme}>
      {theme ? <LightModeIcon /> : <NightlightIcon />}
    </div>
  );
};

export default AppButtonTheme;
