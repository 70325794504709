import { createTheme } from "@mui/material";
import { blue, blueGrey, lightBlue } from "@mui/material/colors";
import colorPalette from "../../styles/colorPalette";

export const DarkTheme = createTheme({
  palette: {
    primary: {
      main: blue[500], // Uma cor vibrante para o tema claro
      dark: blue[800],
      light: blue[400],
      contrastText: "#ccc",
    },
    secondary: {
      main: lightBlue[500], // Uma cor complementar para a cor primária
      dark: lightBlue[400],
      light: lightBlue[300],
      contrastText: "#ccc",
    },
    background: {
      paper: "#424242",
      default: "#191b1c", // Um fundo bem escuro para o tema dark
    },
    text: {
      primary: "#ccc",
      secondary: blueGrey[300],
    },
  },
  components: {
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: colorPalette.dark,
        },
      },
    },
  },
});
