import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, useTheme } from "@mui/material";
import AppButtonTheme from "../../../components/molecules/ButtonTheme/AppButtonTheme";
import BannerLogin from "../../../assets/images/banner_confirmed.svg";

export const ValidScreen: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [confirmed] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      navigate("/schedule");
    }, 3000);
  }, [navigate]);

  return (
    <div style={{ background: theme.palette.background.default, width: "100vw", height: "100vh" }}>
      <AppButtonTheme />
      {confirmed && (
        <div>
          <Box bgcolor={theme.palette.background.default}>
            <div className="main-login">
              <div
                className="card-side"
                style={{
                  color: theme.palette.text.secondary,
                  background: theme.palette.background.default,
                }}
              >
                <div className="left-side">
                  <img src={BannerLogin} alt="banner-login" width="75%" />
                </div>
                <div className="left-side">
                  <Typography variant="h4" gutterBottom align="center" sx={{ color: "text.secondary" }}>
                    O seu email foi verificado!
                  </Typography>
                  <Typography variant="h4" gutterBottom align="center" sx={{ color: "text.secondary" }}>
                    Bem vindo ao StyleTime!
                  </Typography>
                </div>
              </div>
            </div>
          </Box>
        </div>
      )}
    </div>
  );
};
