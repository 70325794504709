import React, { useContext } from "react";
import { BrowserRouter } from "react-router-dom";
import PublicRoutes from "./routes/PublicRoutes";
import PrivateRoutes from "./routes/PrivateRoutes";
import { AuthContext, SignUpBusinessProvider, UserContext } from "./context";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./index.css";
import { AppThemeProvider } from "./context";
import { MobileProvider } from "./context/MobileContext";
import { SnackbarProvider, closeSnackbar } from "notistack";
import Close from "@mui/icons-material/Close";
import LoadingFullSize from "./components/organisms/LoadingFullSize/LoadingFullSize";
import EmailVerifiedRoutes from "./routes/EmailVerifiedRoutes";
import { GoogleOAuthProvider } from "@react-oauth/google";

const App: React.FC = () => {
  const { isAuth, loading } = useContext(AuthContext);
  const { isEmailVerified } = useContext(UserContext);

  return (
    <GoogleOAuthProvider clientId="982110711260-dqnr3dfi2bmsrefspu8k076j7jup8gad.apps.googleusercontent.com">
      <SnackbarProvider
        maxSnack={10}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        action={(snackbarId) => (
          <div
            onClick={() => closeSnackbar(snackbarId)}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <Close />
          </div>
        )}
      >
        <MobileProvider>
          <AppThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <SignUpBusinessProvider>
                <BrowserRouter>
                  {isAuth === undefined || loading ? (
                    <LoadingFullSize />
                  ) : isAuth ? (
                    isEmailVerified ? (
                      <PrivateRoutes />
                    ) : (
                      <EmailVerifiedRoutes />
                    )
                  ) : (
                    <PublicRoutes />
                  )}
                </BrowserRouter>
              </SignUpBusinessProvider>
            </LocalizationProvider>
          </AppThemeProvider>
        </MobileProvider>
      </SnackbarProvider>
    </GoogleOAuthProvider>
  );
};
export default App;
