import axios from "axios";
import { BASE_API_URL } from "../constants/links";

// Configuração inicial do Axios
const api = axios.create({
  baseURL: BASE_API_URL,
  withCredentials: true,
}); // Substitua pela URL base da sua API

// Função para montar o cabeçalho com o token
const mountHeaderToken = (accessToken: string) => {
  api.defaults.headers.common = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };
};

// Interceptor de requisições para adicionar o token
api.interceptors.request.use(
  async (config) => {
    // Substitua getStoreString por sua lógica para obter o token armazenado
    const token = localStorage.getItem("token"); // Exemplo usando localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { api, mountHeaderToken };
