import React from "react";
import { Modal, Box, IconButton } from "@mui/material";
import AppButton from "../../atoms/Button/app-Button";
import AppModalContent from "../../molecules/app-ModalContent/app-ModalContent";
import { Close } from "@mui/icons-material";
interface AppModalProps {
  open: boolean;
  handleClose: () => void;
  handleTerms: (value: boolean) => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const closeButtonStyle = {
  position: "absolute" as "absolute",
  top: 0,
  right: 0,
};

const AppModal: React.FC<AppModalProps> = ({ open, handleClose, handleTerms }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="terms-modal-title"
      aria-describedby="terms-modal-description"
    >
      <Box sx={style}>
        <IconButton sx={closeButtonStyle} onClick={handleClose}>
          <Close />
        </IconButton>
        <AppModalContent
          title="Termos de Condições de Uso"
          description="Aqui você pode adicionar o texto dos Termos e Condições de Uso..."
        />

        <AppButton label="Negar" onClick={() => handleTerms(false)} color="error"></AppButton>
        <AppButton label="Aceitar" onClick={() => handleTerms(true)} color="success"></AppButton>
      </Box>
    </Modal>
  );
};

export default AppModal;
