import { jwtDecode } from "jwt-decode";
import React, { createContext, useState, useEffect, useCallback, useMemo } from "react";
import { getStoreData, setStoreData } from "../utils/storage";

type PropsContextChildren = {
  children: React.ReactNode;
};

type PropsUser = {
  userId: string | null;
  email: string | null;
  userName: string | null;
  emailVerified: boolean;
  establishmentId: string | null;
};

type PropsUserContext = {
  user: PropsUser;
  isEmailVerified: boolean | undefined;
  getUserData: () => void;
  setUserData: (token: string) => Promise<void>;
  clearUsersStates: () => void;
};

const UserContext = createContext<PropsUserContext>({} as PropsUserContext);

const UserProvider: React.FC<PropsContextChildren> = ({ children }) => {
  const [user, setUser] = useState<PropsUser>({} as PropsUser);
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false);

  const setUserData = useCallback(async (token: string) => {
    const userJson: PropsUser = jwtDecode(token);
    setStoreData("user", userJson);
    setUser(userJson);
    setStoreData("emailVerified", userJson.emailVerified);
    setIsEmailVerified(userJson.emailVerified);
  }, []);

  const getUserData = useCallback(async () => {
    const userJson = await getStoreData("user");
    if (userJson) {
      setUser(userJson);
      setIsEmailVerified(userJson.emailVerified);
    }
  }, []);

  const clearUsersStates = useCallback(() => {
    setUser({} as PropsUser);
  }, []);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const value = useMemo(
    () => ({
      user,
      isEmailVerified,
      getUserData,
      setUserData,
      clearUsersStates,
    }),
    [user, isEmailVerified, getUserData, setUserData, clearUsersStates]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
export { UserProvider, UserContext };
export default UserContext;
