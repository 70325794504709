import React from "react";
import "./MainLoginRegister.css";
import { Box, useTheme } from "@mui/material";
import BannerLogin from "../../../assets/images/banner_login.svg";
import AppButtonTheme from "../../molecules/ButtonTheme/AppButtonTheme";
import { useMobileContext } from "../../../context/MobileContext";

interface PropsMain {
  body: any;
}

const MainLoginRegister: React.FC<PropsMain> = ({ body }) => {
  const theme = useTheme();
  const isMobile = useMobileContext();

  return (
    <>
      <AppButtonTheme />
      <div>
        <Box bgcolor={theme.palette.background.default}>
          <div className="main-login">
            <div
              className="card-side"
              style={{
                color: theme.palette.text.secondary,
                background: theme.palette.background.default,
              }}
            >
              {!isMobile && (
                <div className="left-side">
                  <img src={BannerLogin} alt="banner-login" width="75%" />
                </div>
              )}

              <div className="right-side" style={{ background: theme.palette.background.paper }}>
                <div className="right-box">{body()}</div>
              </div>
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};

export default MainLoginRegister;
