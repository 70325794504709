/* eslint-disable jsx-a11y/img-redundant-alt */
import "./LoginScreen.css";
import React, { useCallback, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import AppSelectField from "../../../components/atoms/Select/app-SelectField";
import MainLoginRegister from "../../../components/organisms/MainLoginRegister/MainLoginRegister";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AuthContext } from "../../../context";
import { LoadingButton } from "@mui/lab";
import { DefaultForm } from "./Default/DefaultForm";
import { SocialForm } from "./Social/SocialForm";

interface ILoginScreen {
  userName: string;
  email: string;
  password: string;
  repeatPassword: string;
  keepConnected: boolean;
}

export const LoginScreen: React.FC = () => {
  const { handleCreateUser, handleLogin, handleCreateUserSocial } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const establishmentIdRoute = query.get("id");
  const establishments = [
    {
      value: "d3a3ebf3-5cbf-4a6a-9f7b-a9b57daef6fb",
      label: "aa",
    },
  ];
  const [establishment, setEstablishment] = useState([""]);
  const [isLogin, setIsLogin] = useState(true);

  const navigate = useNavigate();

  const validationCreate = () => {
    return Yup.object().shape({
      userName: Yup.string().required("Nome do usuário é um campo obrigatório"),
      email: Yup.string().required("E-mail é um campo obrigatório").email("E-mail precisa de um valor valido"),
      password: Yup.string().required("Senha é um campo obrigatório").min(8, ""),
      repeatPassword: Yup.string()
        .required("Repetir senha é um campo obrigatório")
        .oneOf([Yup.ref("password")], "A senha informada não é igual a de cima"),
    });
  };
  const validationSocial = () => {
    return Yup.object().shape({
      userName: Yup.string().required("Nome do usuário é um campo obrigatório"),
      email: Yup.string().required("E-mail é um campo obrigatório").email("E-mail precisa de um valor valido"),
    });
  };
  const validationLogin = () => {
    return Yup.object().shape({
      email: Yup.string().required("E-mail é um campo obrigatório").email("E-mail precisa de um valor valido"),
      password: Yup.string().required("Senha é um campo obrigatório").min(8, ""),
    });
  };

  const [profile, setProfile] = useState<any>();

  //#region handleSubmit/Validations

  const getValidationSchema = () => {
    if (profile) {
      return validationSocial();
    } else {
      if (isLogin) {
        return validationLogin();
      } else {
        return validationCreate();
      }
    }
  };

  const { values, setFieldValue, setFieldError, handleSubmit, errors } = useFormik({
    initialValues: {
      userName: "",
      email: "",
      password: "",
      repeatPassword: "",
      establishment: "",
      establishmentId: "",
      keepConnected: false,
    },
    validationSchema: getValidationSchema(),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: ({ userName, email, password, repeatPassword, keepConnected }) => {
      handleSubmitRequest({
        userName,
        email,
        password,
        repeatPassword,
        keepConnected,
      });
    },
  });

  const handleSubmitRequest = async ({ userName, email, password, repeatPassword, keepConnected }: ILoginScreen) => {
    if (!loading) {
      setLoading(true);
      if (isLogin) {
        const loginResult: any = await handleLogin(email, password, keepConnected);
        if (loginResult) navigate("/schedule");
      } else {
        const id = establishmentIdRoute != null ? establishmentIdRoute : establishment.toString();

        const create: any = await handleCreateUser(userName, email, password, repeatPassword, id, keepConnected);
        if (create) navigate("/schedule");
      }
      setLoading(false);
    }
  };

  const handleSubmitRequestSocial = async (accessToken: string) => {
    const create: any = await handleCreateUserSocial(accessToken, values.keepConnected);
    if (create) navigate("/schedule");
    setLoading(false);
  };

  //#endregion

  //#region SetFieldValues

  const handleEstablishment = useCallback(
    (value: any) => {
      setEstablishment(value);
      setFieldValue("establishment", value);
      setFieldError("establishment", "");
    },
    [setFieldValue, setFieldError]
  );
  const setName = useCallback(
    (value: any) => {
      setFieldValue("userName", value);
      setFieldError("userName", "");
    },
    [setFieldValue, setFieldError]
  );
  const setEmail = useCallback(
    (value: any) => {
      setFieldValue("email", value);
      setFieldError("email", "");
    },
    [setFieldValue, setFieldError]
  );
  const setPassword = useCallback(
    (value: any) => {
      setFieldValue("password", value);
      setFieldError("password", "");
    },
    [setFieldValue, setFieldError]
  );
  const setRepeatPassword = useCallback(
    (value: any) => {
      setFieldValue("repeatPassword", value);
      setFieldError("repeatPassword", "");
    },
    [setFieldValue, setFieldError]
  );

  const setKeepConnected = useCallback(
    (value: any) => {
      setFieldValue("keepConnected", value.target.checked);
      setFieldError("keepConnected", "");
    },
    [setFieldValue, setFieldError]
  );
  //#endregion

  const bodyLogin = () => {
    return (
      <form onSubmit={handleSubmit} noValidate className="form">
        <Typography variant="h4" sx={{ color: "text.secondary" }}>
          {isLogin ? "Bem-vindo de volta!" : "Bem vindo!"}
        </Typography>
        <div>
          {!establishmentIdRoute && !isLogin && (
            <AppSelectField
              obrigatorio={true}
              options={establishments}
              label="Estabelecimento"
              value={establishment}
              onChange={(e) => handleEstablishment(e.target.value)}
              error={errors.establishment}
            />
          )}
          <SocialForm
            loading={loading}
            setLoading={setLoading}
            values={values}
            errors={errors}
            setName={setName}
            setEmail={setEmail}
            profile={profile}
            setProfile={setProfile}
            handleSubmitRequestSocial={handleSubmitRequestSocial}
          />
          <DefaultForm
            loading={loading}
            isLogin={isLogin}
            profile={profile}
            values={values}
            errors={errors}
            setName={setName}
            setEmail={setEmail}
            setPassword={setPassword}
            setRepeatPassword={setRepeatPassword}
            setKeepConnected={setKeepConnected}
          />

          {!profile && (
            <div className="Button">
              <LoadingButton type="submit" variant="contained" loading={loading}>
                <span> {isLogin ? "Entrar" : "Cadastrar"}</span>
              </LoadingButton>
              {isLogin ? (
                <LoadingButton variant="outlined" loading={loading} onClick={() => setIsLogin(false)}>
                  <span>Registre-se</span>
                </LoadingButton>
              ) : (
                <LoadingButton variant="outlined" loading={loading} onClick={() => setIsLogin(true)}>
                  <span>Fazer Login</span>
                </LoadingButton>
              )}
            </div>
          )}
        </div>
        {!profile && (
          <div className="Button Button-Establishment">
            <LoadingButton
              variant="outlined"
              color="warning"
              loading={loading}
              onClick={() => {
                if (!loading) {
                  navigate("/signup");
                }
              }}
            >
              <span>sou estabelecimento</span>
            </LoadingButton>
          </div>
        )}
      </form>
    );
  };

  return <MainLoginRegister body={bodyLogin} />;
};

export default LoginScreen;
