import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { AuthContext } from "../../../context";
import AppButtonTheme from "../../../components/molecules/ButtonTheme/AppButtonTheme";
import BannerLogin from "../../../assets/images/banner_login.svg";

export const EmailVerifiedScreen: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { handleLogout } = useContext(AuthContext);

  const handleLogoutClick = () => {
    handleLogout();
    navigate("/");
  };

  return (
    <>
      <AppButtonTheme />
      <div>
        <Box bgcolor={theme.palette.background.default}>
          <div className="main-login">
            <div
              className="card-side"
              style={{
                color: theme.palette.text.secondary,
                background: theme.palette.background.default,
              }}
            >
              <div className="left-side">
                <img src={BannerLogin} alt="banner-login" width="75%" />
              </div>
              <div className="left-side">
                <Typography variant="h4" gutterBottom align="center" sx={{ color: "text.secondary" }}>
                  Verifique sua caixa de email para confirmar seu usuario ou click abaixo para sair!
                </Typography>
                <br />
                <Button variant="contained" color="secondary" onClick={handleLogoutClick}>
                  Sair
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};
