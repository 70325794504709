/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { Checkbox } from "@mui/material";
import AppTextField from "../../../../components/atoms/TextField/AppTextField";

export interface DefaultFormProps {
  loading?: boolean;
  isLogin?: boolean;
  profile?: any;
  values?: any;
  errors?: any;
  setName: (name: any) => void;
  setEmail: (email: any) => void;
  setPassword: (password: any) => void;
  setRepeatPassword: (repeatPassword: any) => void;
  setKeepConnected: (profile: any) => void;
}

export const DefaultForm: React.FC<DefaultFormProps> = ({
  loading,
  isLogin,
  profile,
  values,
  errors,
  setName,
  setEmail,
  setPassword,
  setRepeatPassword,
  setKeepConnected,
}) => {
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    if (event.target.value.length >= 8) {
      setShowRepeatPassword(true);
    } else {
      setShowRepeatPassword(false);
    }
  };

  const handleChangeRepeatPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRepeatPassword(event.target.value);
  };

  return (
    <div>
      {!profile && (
        <div>
          {!isLogin && (
            <AppTextField
              label="Usuário"
              title="Usuário"
              value={values.userName}
              setValue={setName}
              obrigatorio={true}
              error={errors.userName}
              disabled={loading}
            />
          )}

          <AppTextField
            label="E-mail"
            title="E-mail"
            type="email"
            value={values.email}
            setValue={setEmail}
            obrigatorio={true}
            error={errors.email}
            disabled={loading}
          />
          <AppTextField
            label="Senha"
            title="Senha"
            type={"password"}
            value={values.password}
            setValue={setPassword}
            obrigatorio={true}
            isPassword={true}
            onChange={handleChangePassword}
            error={errors.password}
            disabled={loading}
          />
          {!isLogin && showRepeatPassword && (
            <AppTextField
              label="Repetir Senha"
              title="Repetir Senha"
              type="password"
              obrigatorio={true}
              value={values.repeatPassword}
              setValue={setRepeatPassword}
              isPassword={true}
              onChange={handleChangeRepeatPassword}
              error={errors.repeatPassword}
              disabled={loading}
            />
          )}
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={values.keepConnected}
              onChange={setKeepConnected}
              inputProps={{ "aria-label": "controlled" }}
              disabled={loading}
            />
            <p style={{ margin: 0 }}>Mantenha-me conectado</p>
          </div>
        </div>
      )}
    </div>
  );
};
