import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { AuthContext } from "../../../context";
import AppButtonTheme from "../../../components/molecules/ButtonTheme/AppButtonTheme";
import { UseSimpleFetch } from "../../../hooks/useSimpleFetch";

export const EmailValidScreen: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { getToken, handleLogout } = useContext(AuthContext);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token: any = query.get("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await UseSimpleFetch(`/verify-email?token=${encodeURIComponent(token)}`, {
          method: "get",
        });
        if (response.status === 200 && response.data.token) {
          getToken(response.data.token);
          navigate("/valid");
        } else {
          handleLogout();
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [getToken, handleLogout, navigate, token]);

  return (
    <div style={{ background: theme.palette.background.default, width: "100vw", height: "100vh" }}>
      <AppButtonTheme />
    </div>
  );
};
