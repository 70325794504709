import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { LoginScreen, HomePageScreen, SignUpBusinessScreen } from "../screens/unAuthenticated";

const PublicRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePageScreen />} />
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/signup" element={<SignUpBusinessScreen />} />
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};

export default PublicRoutes;
