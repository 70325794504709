import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { UseSimpleFetch } from "../hooks/useSimpleFetch";
import { showSnackbar } from "../utils/snackbar";
import { getStoreData, removeMultiStore, setStoreData } from "../utils/storage";
import { UserContext } from ".";

type PropsContextChildren = {
  children: React.ReactNode;
};

type PropsAuthContext = {
  isAuth: boolean | undefined;
  loading: boolean;
  isOpen: boolean;
  handleCreateEstablishment: (
    nameEstablishment: string,
    phone: string | undefined,
    cpfCnpj: string,
    userName: string,
    email: string,
    password: string,
    repeatPassword: string
  ) => void;
  handleCreateUser: (
    userName: string,
    email: string,
    password: string,
    repeatPassword: string,
    establishmentId: any,
    keepConnected: boolean
  ) => void;
  handleLogin: (email: string, password: string, keepConnected: boolean) => void;
  handleCreateUserSocial: (access_token: string, keepConnected: boolean) => void;
  handleLogout: () => void;
  getSession: () => void;
  getToken: (token: string) => void;
  setToken: (response: any) => void;
  validateSession: () => void;
};

const AuthContext = createContext<PropsAuthContext>({} as PropsAuthContext);

const AuthProvider: React.FC<PropsContextChildren> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isAuth, setIsAuth] = useState<boolean>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { setUserData, clearUsersStates } = useContext(UserContext);

  const getSession = useCallback(async () => {
    const response = await getStoreData("isAuth");
    setIsAuth(response === null ? false : response);
  }, []);

  const getToken = useCallback(
    async (token: string) => {
      setStoreData("isAuth", true);
      setStoreData("token", token);
      setLoading(false);
      setIsAuth(true);
      setUserData(token);
      return;
    },
    [setUserData]
  );

  const handleCreateUser = useCallback(
    async (
      userName: string,
      email: string,
      password: string,
      repeatPassword: string,
      establishmentId: any,
      keepConnected: boolean
    ) => {
      const newUser = {
        userName,
        email,
        password,
        repeatPassword,
        establishmentId,
        keepConnected,
      };

      try {
        const response = await UseSimpleFetch("/users", {
          method: "post",
          params: { ...newUser },
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
          },
        });

        if (response?.status === 201 && response.data) {
          const { token } = response.data;
          getToken(token);
          return true;
        } else if (response.status === 401) {
          throw new Error(response.data.message);
        }

        if (Array.isArray(response.data.error)) {
          response.data.error.forEach((error: string) => {
            showSnackbar(error, "error");
          });
        } else {
          showSnackbar(response.data.error, "error");
        }
      } catch (error: any) {
        showSnackbar(error.message, "error");
        return false;
      }
    },
    [getToken]
  );

  const handleCreateUserSocial = useCallback(
    async (access_token: string, keepConnected: boolean) => {
      const newUser = {
        keepConnected,
        access_token,
      };

      try {
        const response = await UseSimpleFetch("/findOrAddGoogleUser", {
          method: "post",
          params: { ...newUser },
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
          },
        });

        if (response?.status === 201 && response.data) {
          const { token } = response.data;
          getToken(token);
          return true;
        } else if (response.status === 401) {
          throw new Error(response.data.message);
        }

        if (Array.isArray(response.data.error)) {
          response.data.error.forEach((error: string) => {
            showSnackbar(error, "error");
          });
        } else {
          showSnackbar(response.data.error, "error");
        }
      } catch (error: any) {
        showSnackbar(error.message, "error");
        return false;
      }
    },
    [getToken]
  );

  const handleLogout: any = useCallback(async () => {
    setLoading(true);
    setLoading(false);
    setIsOpen(false);
    removeMultiStore().then(() => {
      setIsAuth(false);
      clearUsersStates();
    });
    return;
  }, [clearUsersStates]);
  const handleCreateEstablishment = useCallback(
    async (
      nameEstablishment: string,
      phone: string | undefined,
      cpfCnpj: string,
      userName: string,
      email: string,
      password: string,
      repeatPassword: string
    ) => {
      const newEstablishment = {
        nameEstablishment,
        phone,
        cpfCnpj,
        userName,
        email,
        password,
        repeatPassword,
      };

      try {
        const response = await UseSimpleFetch("/establishments", {
          method: "post",
          params: { ...newEstablishment },
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
          },
        });

        if (response?.status === 201 && response.data) {
          const { token } = response.data;
          getToken(token);
          return true;
        } else if (response.status === 401) {
          throw new Error(response.data.message);
        }

        if (Array.isArray(response.data.error)) {
          response.data.error.forEach((error: string) => {
            showSnackbar(error, "error");
          });
        } else {
          showSnackbar(response.data.error, "error");
        }
      } catch (error: any) {
        showSnackbar(error.message, "error");
        return false;
      }
    },
    [getToken]
  );

  const validateSession = useCallback(async () => {
    const token = {
      token: await getStoreData("token"),
    };
    try {
      const response = await UseSimpleFetch("/validate-token", {
        method: "post",
        params: { ...token },
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
        },
      });
      if (response?.status === 200 && response.data.token) {
        const { token } = response.data;
        getToken(token);
        return true;
      } else if (response.status === 401) {
        throw new Error(response.data.message);
      }
    } catch (error: any) {
      showSnackbar(error.message, "error");
      handleLogout();
      return false;
    }
  }, [getToken, handleLogout]);

  const handleLogin = useCallback(
    async (email: string, password: string, keepConnected: boolean) => {
      //    setLoading(true);
      if (email) {
        const login = {
          email,
          password,
          keepConnected,
        };
        try {
          const response = await UseSimpleFetch("/login", {
            method: "post",
            params: { ...login },
            headers: {
              accept: "*/*",
              "Content-Type": "application/json",
            },
          });

          if (response?.status === 200 && response.data) {
            const { token } = response.data;
            getToken(token);
            validateSession();
            return true;
          } else if (response.status === 401) {
            throw new Error(response.data.message);
          }
          //  setLoading(false);
        } catch (error: any) {
          //  setLoading(false);
          showSnackbar(error.message, "error");
          return false;
        }
      }
      // setLoading(false);
      return false;
    },
    [getToken, validateSession]
  );

  const setToken = useCallback(
    (response: any) => {
      if (response.status === 401) {
        handleLogout();
      } else {
        if (response.data.token) {
          const newToken: string = response.data.token;
          console.log(newToken);
          getToken(newToken);
        }
      }
    },
    [getToken, handleLogout]
  );

  useEffect(() => {
    getSession();
  }, [getSession]);

  // useEffect(() => {
  //   if (isAuth) {
  //     console.log("isAuth");
  //   }
  // }, [isAuth]);

  const value = useMemo(
    () => ({
      isAuth,
      loading,
      isOpen,
      handleCreateUser,
      handleCreateEstablishment,
      handleLogin,
      handleCreateUserSocial,
      handleLogout,
      getSession,
      getToken,
      setToken,
      validateSession,
    }),
    [
      isAuth,
      loading,
      isOpen,
      handleCreateUser,
      handleCreateEstablishment,
      handleLogin,
      handleCreateUserSocial,
      handleLogout,
      getSession,
      getToken,
      setToken,
      validateSession,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export { AuthProvider, AuthContext };
export default AuthContext;
