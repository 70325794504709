import secureLocalStorage from "react-secure-storage";

export const setStoreString = (key: string, value: string) => {
  try {
    secureLocalStorage.setItem(`@style-time:${key}`, value);
  } catch (error) {
    console.log(error);
  }
};

export const getStoreString = (key: string) => {
  try {
    const value = secureLocalStorage.getItem(`@style-time:${key}`);
    return value;
  } catch (error) {
    console.log(error);
  }
};

export const setStoreData = (key: string, value: any) => {
  try {
    secureLocalStorage.setItem(`@style-time:${key}`, JSON.stringify(value));
  } catch (error) {
    console.log(error);
  }
};

export const getStoreData = (key: string) => {
  try {
    const value: any = secureLocalStorage.getItem(`@style-time:${key}`);
    if (value !== null) {
      return JSON.parse(value);
    }
    return null;
  } catch (error) {
    console.log(error);
  }
};

export const removeItemStore = (key: string) => {
  try {
    secureLocalStorage.removeItem(`@style-time:${key}`);
  } catch (error) {
    console.log(error);
  }
};

export const removeMultiStore = async () => {
  try {
    secureLocalStorage.clear();
  } catch (error) {
    console.log(error);
  }
};
