import Loading from "../../molecules/Loading";
import AppButtonTheme from "../../molecules/ButtonTheme/AppButtonTheme";
import { useTheme } from "@mui/material";

const LoadingFullSize = () => {
  const theme = useTheme();

  return (
    <div style={{ background: theme.palette.background.default, width: "100vw", height: "100vh" }}>
      <AppButtonTheme />
      <Loading color="primary" />
    </div>
  );
};

export default LoadingFullSize;
