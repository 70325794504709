import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { cpfMask } from "../../../utils/cpfMask";
import { cnpjMask } from "../../../utils/cnpjMask";
import { telMask } from "../../../utils/telMask";
import "./AppTextField.css";
import { hasLowerCase, hasNumber, hasSpecialChar, hasUpperCase, isLongEnough } from "../../../utils/password";
import { Visibility, VisibilityOff, Block, Check } from "@mui/icons-material";

interface TextFieldProps {
  label?: string;
  title?: string;
  obrigatorio?: boolean;
  value?: any;
  maxLength?: number;
  minLenght?: number;
  className?: string;
  isPassword?: boolean;
  type?: string;
  error?: string | undefined;
  disabled?: boolean;
  setValue: Dispatch<SetStateAction<string>>;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const AppTextField = React.memo(
  ({
    label,
    title,
    obrigatorio = false,
    maxLength = 200,
    minLenght = 0,
    className,
    value = "",
    type,
    isPassword = false,
    error,
    disabled,
    setValue,
    onChange,
  }: TextFieldProps) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleChange = useCallback(
      (event: any) => {
        const { value } = event.target;
        if (label === "CPF/CNPJ") {
          if (value.length < 15) {
            setValue(cpfMask(value));
          } else {
            setValue(cnpjMask(value));
          }
        } else if (label === "Telefone (opcional)" || label === "Telefone" || label === "Telefone*") {
          setValue(telMask(value));
        } else if (label === "Nome") {
          setValue(value);
        } else {
          setValue(value);
        }

        if (onChange) {
          onChange(event);
        }
      },
      [label, setValue, onChange]
    );

    const isPasswordValid = () => {
      return (
        hasUpperCase(value) && hasLowerCase(value) && hasSpecialChar(value) && isLongEnough(value) && hasNumber(value)
      );
    };

    const Icon = (functionExec: any) => {
      if (functionExec) {
        return <Check sx={{ fontSize: "10px", marginRight: "5px" }} />;
      }
      return <Block sx={{ fontSize: "10px", marginRight: "5px" }} />;
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    const endAdornment = () => {
      if (isPassword) {
        return (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        );
      } else {
        return "";
      }
    };

    return (
      <>
        <TextField
          label={label && <label>{label}</label>}
          title={title ?? ""}
          fullWidth
          margin="normal"
          className={className ?? ""}
          type={showPassword ? "text" : type ?? ""}
          value={value}
          required={obrigatorio}
          onChange={handleChange}
          helperText={error ? error : ""}
          error={error ? true : false}
          inputProps={{
            maxLength: maxLength,
            minLength: minLenght,
          }}
          InputProps={{ endAdornment: endAdornment() }}
          disabled={disabled}
        />
        {isPassword && value.length > 0 && !isPasswordValid() && (
          <div style={{ textAlign: "start" }}>
            <p className="format-password " style={{ color: isLongEnough(value) ? "green" : "red" }}>
              {Icon(isLongEnough(value))} Deve conter ao menos 8 caracteres
            </p>
            <p className="format-password " style={{ color: hasNumber(value) ? "green" : "red" }}>
              {Icon(hasNumber(value))} Deve conter ao menos 1 número
            </p>
            <p className="format-password " style={{ color: hasUpperCase(value) ? "green" : "red" }}>
              {Icon(hasUpperCase(value))} Deve conter ao menos 1 letra maiúscula
            </p>
            <p className="format-password " style={{ color: hasLowerCase(value) ? "green" : "red" }}>
              {Icon(hasLowerCase(value))} Deve conter ao menos 1 letra minúscula
            </p>
            <p className="format-password " style={{ color: hasSpecialChar(value) ? "green" : "red" }}>
              {Icon(hasSpecialChar(value))} Deve conter ao menos 1 caractere especial
            </p>
          </div>
        )}
      </>
    );
  }
);

export default AppTextField;
