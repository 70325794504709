import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider, UserProvider } from "./context";

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <UserProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </UserProvider>
    </React.StrictMode>
  );
} else {
  console.error("Falha ao encontrar o elemento root");
}
